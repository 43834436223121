* {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  background: #ffffff;
}

body {
  direction: rtl;
  height: 100%;
  margin: 0px;
}

.heading {
  width: 100%;
  text-align: center;
  font-size: 4.16vw;
  padding: 30px;
}

.heading img {
  float: right;
  margin: 0 30px 30px 0;
}

.banner {
  width: 100%;
  height: 600px;
  color: white;
  display: flex;
  align-items: center;
  padding-right: 130px;
  background-size: cover;
}

.banner_centered {
  width: 600px;
  height: 400px;
}

.banner_title {
  font-size: 4.16vw;
}

.banner_text {
  font-size: 1.45vw;
}

.content {
  max-width: 70%;
  margin: 0 auto;
  font-size: 1.04vw;
}

.content_title {
  margin-top: 40px;
  margin-bottom: 40px;
  font-weight: bold;
}

.content_list {
  list-style: none;
  margin-bottom: 100px;
}

.content_list li {
  margin-top: 10px;
}

.about_us {
  width: 100%;
  height: 400px;
  background-color: rgb(197, 197, 197);
  font-size: 1.45vw;
}

.about_us h2 {
  text-align: center;
  font-size: 4.16vw;
}

.about_text {
  max-width: 70%;
  margin: 0 auto;
}

.footer {
  width: 100%;
  height: 200px;
  background-color: rgb(88, 88, 88);
  color: #ffffff;
}

.footer ul {
  margin-block-start: 0;
  margin-block-end: 0;
  list-style: none;
  padding-top: 70px;
}

.footer {
  display: flex;
}

.footer ul {
  flex: 1;
}

.eng_info_footer {
  direction: ltr;
}

.contact_us_link, .contact_us_link:hover, .contact_us_link:focus, .contact_us_link:active {
  text-decoration: none;
  color: inherit;
}

/* CSS */
.button-35 {
  float: left;
  align-items: center;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: transparent 0 0 0 3px,rgba(18, 18, 18, .1) 0 6px 20px;
  box-sizing: border-box;
  color: #121212;
  cursor: pointer;
  display: inline-flex;
  flex: 1 1 auto;
  font-family: Inter,sans-serif;
  font-size: 1.2rem;
  font-weight: 700;
  justify-content: center;
  line-height: 1;
  margin: 0;
  outline: none;
  padding: 1rem 1.2rem;
  text-align: center;
  text-decoration: none;
  transition: box-shadow .2s,-webkit-box-shadow .2s;
  white-space: nowrap;
  border: 0;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-35:hover {
  box-shadow: #121212 0 0 0 3px, transparent 0 0 0 0;
}